var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"9"}},[_c('Fieldset',{attrs:{"id":"shelfinfo","title":_vm.$t('Shelf Info')}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Warehouse') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Warehouse","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('treeselect',{attrs:{"multiple":false,"clearable":true,"searchable":true,"open-on-click":true,"clear-on-select":true,"show-count":true,"options":_vm.warehouses,"limit":5,"max-height":400,"placeholder":_vm.$t('Select the warehouse')},model:{value:(_vm.model.storage_id),callback:function ($$v) {_vm.$set(_vm.model, "storage_id", $$v)},expression:"model.storage_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Shelf code') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Shelf code","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Shelf code')},model:{value:(_vm.model.code),callback:function ($$v) {_vm.$set(_vm.model, "code", $$v)},expression:"model.code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Name') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Name')},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Location in the warehouse') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Place"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Location in the warehouse')},model:{value:(_vm.model.location),callback:function ($$v) {_vm.$set(_vm.model, "location", $$v)},expression:"model.location"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Tag') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Tag"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('treeselect',{attrs:{"multiple":true,"clearable":true,"searchable":true,"open-on-click":true,"clear-on-select":true,"show-count":true,"options":_vm.items,"limit":5,"max-height":400},on:{"input":_vm.handlerChangeCategory},model:{value:(_vm.model.cat_slug),callback:function ($$v) {_vm.$set(_vm.model, "cat_slug", $$v)},expression:"model.cat_slug"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Floors') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Floors","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Floors')},model:{value:(_vm.model.floor),callback:function ($$v) {_vm.$set(_vm.model, "floor", $$v)},expression:"model.floor"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":"*" + _vm.$t('Quantity') + ":","label-cols-md":"12"}},[_c('validation-provider',{attrs:{"name":"Quantity","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":_vm.$t('Quantity')},model:{value:(_vm.model.quantity),callback:function ($$v) {_vm.$set(_vm.model, "quantity", $$v)},expression:"model.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('QR code')+":","label-cols-md":"12"}},[_c('div',{staticStyle:{"height":"200px"}},[(_vm.isQrCode)?_c('vrcode',{attrs:{"download":{
                      text: 'Download',
                      filename: 'file-name.png',
                      visible: true, type: 'image/png'
                    },"value":_vm.model.code,"options":{
                      size: 180,
                      level: 'Q'
                    }}}):_vm._e()],1)])],1)],1)],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('div',{staticClass:"position-sticky top"},[_c('Fieldset',{attrs:{"id":"status","title":_vm.$t('Status')}},[_c('b-form-group',{attrs:{"label":_vm.$t('Display on/off')+":","label-cols-md":"6"}},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"checked":"true","name":"check-button","switch":""},model:{value:(_vm.model.is_active),callback:function ($$v) {_vm.$set(_vm.model, "is_active", $$v)},expression:"model.is_active"}})],1),_c('hr'),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"text-right",attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" "+_vm._s(_vm.$t('Update'))+" ")])],1)],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
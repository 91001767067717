import { render, staticRenderFns } from "./ShelfEdit.vue?vue&type=template&id=74e2f06a&scoped=true&"
import script from "./ShelfEdit.vue?vue&type=script&lang=js&"
export * from "./ShelfEdit.vue?vue&type=script&lang=js&"
import style0 from "./ShelfEdit.vue?vue&type=style&index=0&id=74e2f06a&prod&lang=scss&scoped=true&"
import style1 from "@riophae/vue-treeselect/dist/vue-treeselect.min.css?vue&type=style&index=1&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74e2f06a",
  null
  
)

export default component.exports